@font-face {
  font-family: PayPal-Sans;
  font-style: normal;
  font-weight: 400;
  src: url('https://www.paypalobjects.com/ui-web/paypal-sans-small/1-0-0/PayPalSansSmall-Regular.eot');
  /* IE9 Compat Modes */
  src: url('https://www.paypalobjects.com/ui-web/paypal-sans-small/1-0-0/PayPalSansSmall-Regular.woff2') format('woff2'), /* Moderner Browsers */ url('https://www.paypalobjects.com/ui-web/paypal-sans-small/1-0-0/PayPalSansSmall-Regular.woff') format('woff'), /* Modern Browsers */ url('https://www.paypalobjects.com/ui-web/paypal-sans-small/1-0-0/PayPalSansSmall-Regular.svg#69ac2c9fc1e0803e59e06e93859bed03') format('svg');
  /* Legacy iOS */
}
@font-face {
  font-family: PayPal-Sans;
  font-style: normal;
  font-weight: 500;
  src: url('https://www.paypalobjects.com/ui-web/paypal-sans-small/1-0-0/PayPalSansSmall-Medium.eot');
  /* IE9 Compat Modes */
  src: url('https://www.paypalobjects.com/ui-web/paypal-sans-small/1-0-0/PayPalSansSmall-Medium.woff2') format('woff2'), /* Moderner Browsers */ url('https://www.paypalobjects.com/ui-web/paypal-sans-small/1-0-0/PayPalSansSmall-Medium.woff') format('woff'), /* Modern Browsers */ url('https://www.paypalobjects.com/ui-web/paypal-sans-small/1-0-0/PayPalSansSmall-Medium.svg#69ac2c9fc1e0803e59e06e93859bed03') format('svg');
  /* Legacy iOS */
}
/* ---------------------------------------------------------------------------------------- */
@font-face {
  font-family: PayPal-Sans-Big;
  font-style: normal;
  font-weight: 300;
  src: url('https://www.paypalobjects.com/ui-web/paypal-sans-big/1-0-0/PayPalSansBig-Light.eot');
  /* IE9 Compat Modes */
  src: url('https://www.paypalobjects.com/ui-web/paypal-sans-big/1-0-0/PayPalSansBig-Light.woff2') format('woff2'), /* Moderner Browsers */ url('https://www.paypalobjects.com/ui-web/paypal-sans-big/1-0-0/PayPalSansBig-Light.woff') format('woff'), /* Modern Browsers */ url('https://www.paypalobjects.com/ui-web/paypal-sans-big/1-0-0/PayPalSansBig-Light.svg#69ac2c9fc1e0803e59e06e93859bed03') format('svg');
  /* Legacy iOS */
}
@font-face {
  font-family: PayPal-Sans-Big;
  font-style: normal;
  font-weight: 400;
  src: url('https://www.paypalobjects.com/ui-web/paypal-sans-big/1-0-0/PayPalSansBig-Regular.eot');
  /* IE9 Compat Modes */
  src: url('https://www.paypalobjects.com/ui-web/paypal-sans-big/1-0-0/PayPalSansBig-Regular.woff2') format('woff2'), /* Moderner Browsers */ url('https://www.paypalobjects.com/ui-web/paypal-sans-big/1-0-0/PayPalSansBig-Regular.woff') format('woff'), /* Modern Browsers */ url('https://www.paypalobjects.com/ui-web/paypal-sans-big/1-0-0/PayPalSansBig-Regular.svg#69ac2c9fc1e0803e59e06e93859bed03') format('svg');
  /* Legacy iOS */
}

/**
 * DO NOT EDIT THIS FILE AS IT'S GENERATED CODE.
 * PLEASE NOTE: If you have specific icon needs, place them in your project's own Less file.
*/
/*
 * Use this for local testing
 *
@font-face {
	font-family: 'consumer-icons';

	src: url('fonts/ConsumerIcons-Regular.eot');
	src: url('fonts/ConsumerIcons-Regular.eot?#iefix') format('embedded-opentype'),
		url('fonts/ConsumerIcons-Regular.woff2') format('woff2'),
		url('fonts/ConsumerIcons-Regular.woff') format('woff'),
		url('fonts/ConsumerIcons-Regular.ttf')  format('truetype'),
		url('fonts/ConsumerIcons-Regular.svg#69ac2c9fc1e0803e59e06e93859bed03') format('svg');

	font-style: normal;
	font-weight: 400;
} */
/*
 * Use this for production
 *
 */
@font-face {
  font-family: 'consumer-icons';
  src: url('https://www.paypalobjects.com/ui-web/iconfont-consumer/4-0-1/fonts/ConsumerIcons-Regular.eot');
  src: url('https://www.paypalobjects.com/ui-web/iconfont-consumer/4-0-1/fonts/ConsumerIcons-Regular.eot?#iefix') format('embedded-opentype'), url('https://www.paypalobjects.com/ui-web/iconfont-consumer/4-0-1/fonts/ConsumerIcons-Regular.woff2') format('woff2'), url('https://www.paypalobjects.com/ui-web/iconfont-consumer/4-0-1/fonts/ConsumerIcons-Regular.woff') format('woff'), url('https://www.paypalobjects.com/ui-web/iconfont-consumer/4-0-1/fonts/ConsumerIcons-Regular.ttf') format('truetype'), url('https://www.paypalobjects.com/ui-web/iconfont-consumer/4-0-1/fonts/ConsumerIcons-Regular.svg#69ac2c9fc1e0803e59e06e93859bed03') format('svg');
  font-style: normal;
  font-weight: 400;
}
/*
 * Use this for containing an icon for vertical and
 * horizontal centering. Just apply the width and height
 * to a custom class applied to this same element.
 */
.icon,
.checkbox input[type='checkbox'] + label:after {
  font-family: 'consumer-icons';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 0;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon:before {
  font-size: 1.3em;
}
.icon-close-small:before {
  content: "\2716";
}
.icon-arrow-down-small:before {
  content: "\2193";
}
@keyframes spin {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fade-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes fade-in-with-translate {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  30% {
    transform: translateY(0);
  }
  100% {
    opacity: 1;
  }
}
@keyframes url-companion-bounce {
  50% {
    transform: scale(1.2);
  }
}
@keyframes shake {
  16.7% {
    transform: translateX(-10px);
  }
  33.3% {
    transform: translateX(10px);
  }
  50% {
    transform: translateX(-5px);
  }
  66.7% {
    transform: translateX(5px);
  }
  83.3% {
    transform: translateX(-2px);
  }
}
@keyframes grab-button-bounce-up {
  0% {
    margin-top: 25px;
  }
  50% {
    margin-top: 16px;
  }
  100% {
    margin-top: 25px;
  }
}
@keyframes grow-with-bounce {
  0% {
    transform: scale(0.8);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes rotate-word {
  0% {
    opacity: 0;
  }
  2% {
    opacity: 0;
    transform: translateY(-30px);
  }
  5% {
    opacity: 1;
    transform: translateY(0px);
  }
  15% {
    opacity: 1;
    transform: translateY(0px);
  }
  18% {
    opacity: 0;
    transform: translateY(30px);
  }
  80% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
@keyframes flip-in {
  0% {
    transform: rotateY(-180deg);
  }
  25% {
    transform: rotateY(40deg);
  }
  50% {
    transform: rotateY(-20deg);
  }
  75% {
    transform: rotateY(10deg);
  }
  100% {
    transform: rotateY(0deg);
  }
}
@keyframes flip-out {
  0% {
    transform: rotateY(0deg);
  }
  25% {
    transform: rotateY(220deg);
  }
  50% {
    transform: rotateY(160deg);
  }
  75% {
    transform: rotateY(190deg);
  }
  100% {
    transform: rotateY(180deg);
  }
}
@keyframes grow-to-dynamic-small-height {
  0% {
    padding-bottom: 0;
    padding-top: 0;
    max-height: 0;
  }
  100% {
    max-height: 200px;
  }
}
@keyframes shrink-to-dynamic-small-height {
  0% {
    max-height: 200px;
  }
  100% {
    padding-bottom: 0;
    padding-top: 0;
    max-height: 0;
  }
}
::-ms-clear {
  width: 0;
  height: 0;
}
::-ms-reveal {
  width: 0;
  height: 0;
}
a {
  color: #0070ba;
  text-decoration: none;
  font-weight: 400;
}
a:hover,
a:focus {
  text-decoration: underline;
  color: #005ea6;
}
@media screen and (max-width: 767px) {
  .hide-on-mobile {
    display: none !important;
  }
}
@media screen and (min-width: 768px) {
  .hide-on-desktop {
    display: none !important;
  }
}
.main-title {
  font-family: 'PayPal-Sans-Big', 'HelveticaNeue-Light', 'PayPal-Sans-Big', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-weight: 300;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 26px;
  line-height: 29px;
  text-align: center;
}
.bold {
  font-weight: 500 !important;
}
#footer {
  margin-top: -30px;
  position: relative;
  box-sizing: border-box;
}
@media screen and (min-width: 768px) {
  #footer {
    margin-top: -73px;
  }
}
#footer .footers-container {
  height: 99%;
}
@media screen and (min-width: 768px) {
  #footer .ppme-footer {
    display: none;
  }
}
#footer .ppme-footer .links {
  padding: 0 15px;
  background-color: #f7f9fa;
  border-top: solid 1px #cecece;
}
#footer .ppme-footer .links a {
  font-family: 'PayPal-Sans-Big', 'HelveticaNeue-Medium', 'PayPal-Sans-Big', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-weight: 500;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 14px;
  line-height: 55px;
  display: inline-block;
  margin-right: 20px;
  color: #2c2e2f;
}
header {
  height: 50px;
  padding: 10px 15px;
  box-sizing: border-box;
  overflow: hidden;
}
@media screen and (min-width: 768px) {
  header {
    background-color: white;
    height: 70px;
    padding: 14px 60px;
  }
}
@media screen and (max-width: 1000px) {
  header {
    padding: 11px 15px;
  }
}
header .header-content {
  max-width: 1170px;
  margin: 0 auto;
  padding: 0 15px;
}
header .paypal-logo {
  background-image: url('https://www.paypalobjects.com/digitalassets/c/consumer/paypalme/networkidentity/pp_logo_small.png');
  width: 30px;
  height: 30px;
  background-repeat: no-repeat;
  background-position-y: center;
  background-position-x: 0 /*rtl:ignore*/;
  background-size: cover, 100%;
  display: inline-block;
  float: left;
  margin-top: 5px;
}
@media screen and (max-width: 767px) {
  header .paypal-logo {
    margin-top: 0;
  }
}
@media screen and (min-width: 1000px) {
  header .paypal-logo {
    background-position-x: center;
    display: inline-block;
    background-size: 100%;
    width: 119px;
    height: 29px;
    margin: 6px 0;
  }
}
header .links {
  margin-left: 15px;
  float: left;
  display: none;
  height: 43px;
}
@media screen and (min-width: 768px) {
  header .links {
    display: inline-block;
  }
}
header .links .link-list-item {
  display: inline-block;
  border-left: solid 1px #e1e5e8;
  padding-right: 15px;
  padding-left: 14px;
  font-family: 'PayPal-Sans-Big', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-weight: 400;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 15px;
  line-height: 43px;
  color: #2c2e2f;
}
header .links .link-list-item:hover,
header .links .link-list-item:focus {
  color: #0079c1;
  text-shadow: none;
  text-decoration: none;
}
header .links .link-list-item:last-child {
  padding-right: 0;
}
header .my-paypal-me {
  color: #2c2e2f;
  font-family: 'PayPal-Sans-Big', 'HelveticaNeue-Bold', 'PayPal-Sans-Big', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-weight: 600;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 13px;
  line-height: 30px;
}
@media screen and (min-width: 768px) {
  header .my-paypal-me {
    font-family: 'PayPal-Sans-Big', 'HelveticaNeue-Bold', 'PayPal-Sans-Big', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-weight: 600;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-size: 15px;
    line-height: 43px;
  }
}
header .my-paypal-me:hover {
  text-decoration: none;
  color: #0070ba;
}
.old-browser-warning {
  background-color: #ffd571;
  color: #2c2e2f;
  text-align: center;
  font-family: 'PayPal-Sans-Big', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-weight: 400;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 15px;
  line-height: 15px;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 5px;
  padding-right: 5px;
}
.old-browser-warning p:first-child {
  margin-top: 0;
}
.old-browser-warning .browser-list .browser-link {
  margin-right: 20px;
}
.old-browser-warning .browser-list .browser-link img {
  border: 0;
  vertical-align: middle;
  width: 60px;
  height: 60px;
  margin-right: 10px;
}
.old-browser-warning .browser-list .browser-link.ff img {
  width: 80px;
  height: 80px;
}
ul.inline {
  list-style: none;
  padding: 0;
}
ul.inline > li {
  display: inline-block;
  padding-right: 5px;
  padding-left: 5px;
  line-height: 21px;
}
.paypal-footer {
  width: 100%;
  box-sizing: border-box;
  border-top: 1px solid #fff;
  margin-bottom: 0;
  font-family: 'PayPal-Sans-Big', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-weight: 400;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 15px;
  line-height: 21px;
  left: 0;
  right: 0;
  float: none;
  position: relative;
  background: #f5f5f5;
  clear: both;
  color: #666666;
  text-shadow: 0 1px 1px #fff;
}
.paypal-footer * {
  box-sizing: border-box;
}
.paypal-footer p {
  margin: 0 0 10.5px;
}
.paypal-footer ul {
  margin: 0 0 10.5px 25px;
}
.paypal-footer .navbar-inner {
  height: 99%;
  /* needed to make sure the height is constrained to the height set on the footer element */
  border-top: 1px solid #d4d4d4;
  background-image: linear-gradient(to bottom, #ffffff, #f2f2f2);
  background-repeat: repeat-x;
}
.paypal-footer .navbar-inner a:hover,
.paypal-footer .navbar-inner a:focus {
  color: #0079c1;
  text-shadow: none;
}

.paypal-footer .footerList-links {
  display: inline-block;
  margin: 0;
  vertical-align: top;
}
.paypal-footer .footerList-links li {
  margin-top: 10px;
  margin-right: 13px;
}
.paypal-footer .footerList-links li a {
  color: #666666;
  font-weight: normal;
  text-shadow: 0 1px 1px #fff;
}
.paypal-footer .footerList-links li a:hover,
.paypal-footer .footerList-links li a:focus,
.paypal-footer .footerList-links li a:active,
.paypal-footer .footerList-links li .active {
  color: #0070ba;
  text-decoration: none;
}
@media screen and (max-width: 767px) {
  .paypal-footer .footerList-links li {
    box-sizing: border-box;
    font-family: 'PayPal-Sans-Big', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-weight: 400;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-size: 13px;
    line-height: 49px;
    text-decoration: none;
    color: #2c2e2f;
    display: block;
    text-align: left;
    border-bottom: solid 1px #f2f2f2;
    margin: 0;
    padding: 0 15px;
  }
  .paypal-footer .footerList-links li:first-child {
    border-top: solid 1px #f2f2f2;
  }
}
@media screen and (min-width: 768px) {
  .paypal-footer .footerList-links li.country-list-item {
    display: none;
  }
}
.paypal-footer .footerList-links li.country-list-item {
  padding-left: 10px;
}
.paypal-footer .country-selector-container {
  display: inline-block;
  width: 34%;
  text-align: right;
  padding-top: 20px;
}
@media screen and (max-width: 767px) {
  .paypal-footer .country-selector-container {
    display: none;
  }
}
.paypal-footer .country-selector-link {
  display: inline-block;
  vertical-align: middle;
  height: 20px;
  width: 32px;
}
@media screen and (max-width: 767px) {
  .paypal-footer .country-selector-link {
    margin-top: -6px;
  }
}
.paypal-footer .country-selector-link .country-selector {
  width: 32px;
  height: 20px;
}
.paypal-footer .language-link {
  color: #666;
  font-family: 'PayPal-Sans-Big', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-weight: 400;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 13px;
  line-height: 20px;
  display: inline-block;
  padding: 1px 0 2px 6px;
  vertical-align: middle;
  border-left: 1px solid #ddd;
  margin: 0 2px 0 4px;
  text-transform: capitalize;
}
@media screen and (min-width: 768px) {
  .paypal-footer .language-link:first-child {
    border-left: none;
  }
}
@media screen and (max-width: 767px) {
  .paypal-footer .language-link:nth-child(2) {
    border-left: none;
  }
}
.paypal-footer .footerList-links_primary {
  width: 65%;
  padding-left: 5px;
}
@media screen and (max-width: 767px) {
  .paypal-footer .footerList-links_primary {
    width: 100%;
    padding-left: 0;
  }
}
.paypal-footer .footerList-links_primary:first-child {
  margin-top: 10px;
  font-size: 15px;
}
@media screen and (max-width: 767px) {
  .paypal-footer .footerList-links_primary:first-child {
    margin-top: 0;
    border-top: none;
  }
}
.paypal-footer .footerCopyright,
.paypal-footer .footerList-links_secondary {
  display: inline-block;
  vertical-align: top;
}
@media screen and (max-width: 767px) {
  .paypal-footer .footerCopyright,
  .paypal-footer .footerList-links_secondary {
    display: inline-block;
  }
}
.paypal-footer .footerList-links_secondary {
  margin-left: 15px;
  font-size: 12px;
}
@media screen and (max-width: 767px) {
  .paypal-footer .footerList-links_secondary li {
    display: inline-block;
    padding: 0;
  }
  .paypal-footer .footerList-links_secondary li:first {
    margin-left: 10px;
  }
  .paypal-footer .footerList-links_secondary li a {
    font-family: 'PayPal-Sans-Big', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-size: 12px;
    line-height: 12px;
    font-weight: 400;
    color: #666;
    margin-right: 20px;
    text-shadow: none;
  }
}
.paypal-footer .footerCopyright {
  margin-top: 10px;
  padding-left: 15px;
  font-size: 12px;
}
@media screen and (min-width: 768px) {
  .paypal-footer .footerCopyright {
    padding-left: 10px;
  }
}
.paypal-footer .footerCopyright {
  color: #999;
}
@media screen and (max-width: 767px) {
  .paypal-footer .footerCopyright {
    display: block;
  }
}
.paypal-footer .footerCopyright:after {
  margin-left: 20px;
  content: "";
}
@media screen and (min-width: 768px) {
  .paypal-footer .footerCopyright:after {
    content: "\7c";
  }
}
.paypal-footer .footerDisclaimer {
  padding: 0.5em 15px 1em;
  font-size: 12px;
  line-height: 1.5em;
}
@media screen and (min-width: 768px) {
  .paypal-footer .footerDisclaimer {
    padding: 0.5em 10px 1em;
  }
}
.paypal-footer .navbar-content {
  max-width: 1170px;
  margin: 0 auto;
  padding: 0 15px;
}
@media screen and (max-width: 767px) {
  .paypal-footer .navbar-content {
    padding: 0;
  }
}
section.modal {
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  z-index: -9999;
  transition: all 0.5s;
  display: none;
  background-color: white;
  overflow: visible;
  opacity: 0;
}
section.modal.shown {
  opacity: 1;
}
.page-box {
  background-color: white;
  background-clip: content-box;
  padding-top: 65px;
  -ms-transform: translate3d(0, 0, 0);
  -webkit-transform: translate3d(0, 0, 0);
  -webkit-backface-visibility: hidden;
  width: 100%;
  margin: 0 auto;
  overflow: hidden;
}
.page-box section.content-container {
  position: relative;
  min-height: 175px;
  box-sizing: border-box;
  background-color: #fff;
  margin: 40px auto;
  max-width: 286px;
  color: #2c2e2f;
  overflow: visible;
  transition: all 0.4s ease-out 0.2s;
}
@media screen and (min-width: 768px) {
  .page-box section.content-container {
    max-width: none;
    margin: 40px 97px;
  }
}
@media screen and (min-width: 768px) {
  .page-box {
    width: 480px;
    padding-top: 130px;
    padding-bottom: 130px;
  }
  .page-box.wide {
    width: 580px;
  }
}
.paypal-button,
.paypal-button-transparent {
  appearance: none;
  box-sizing: border-box;
  font-family: 'PayPal-Sans-Big', 'HelveticaNeue-Medium', 'PayPal-Sans-Big', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-weight: 500;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 15px;
  line-height: normal;
  background: none repeat 0 0 #0070ba;
  border-radius: 24px;
  border: 1px solid transparent;
  color: #fff !important;
  cursor: pointer;
  display: block;
  padding: 9px 15px 11px;
  text-align: center;
  vertical-align: middle;
  outline: none;
  white-space: normal;
  transition: background 0.5s ease;
}
.paypal-button:hover,
.paypal-button:active {
  background: none repeat 0 0 #003087;
  text-decoration: none;
}
.paypal-button-transparent {
  color: #009cde !important;
  background-color: transparent;
  border-color: #009cde;
}
.paypal-button-transparent:hover,
.paypal-button-transparent:active,
.paypal-button-transparent:focus {
  background-color: transparent;
  text-decoration: none;
  box-shadow: 0px 0px 0px 1px #009cde;
}
.checkbox input[type='checkbox'] {
  display: none;
}
.checkbox input[type='checkbox'] + label {
  display: inline-block;
  width: 25px;
  height: 25px;
  background-color: white;
  border-radius: 4px;
  border: 1px solid #9da3a6;
  box-sizing: border-box;
  cursor: pointer;
  position: relative;
}
.checkbox input[type='checkbox'] + label:after {
  color: white;
  font-size: 22px;
  content: '';
  position: absolute;
  top: 7px;
  left: 0;
  height: 100%;
  width: 100%;
  text-align: center;
}
.checkbox input[type='checkbox']:checked + label {
  background-color: #0070ba;
  border: 1px solid #0070ba;
}
.checkbox input[type='checkbox']:checked + label:after {
  content: '\e014';
}
.country-selector {
  background: transparent url('https://www.paypalobjects.com/webstatic/mktg/icons/sprite_countries_flag4.png') 5px 100px no-repeat;
}

.country-selector.US {
  background-position: 5px -383px;
}

.country-selector.TH {
  background-position: 5px -804px;
}

.country-selector.CH {
  background-position: 5px -944px;
}

.country-selector.MY {
  background-position: 5px -2379px;
}

.country-selector.KR {
  background-position: 5px -3254px;
}

.country-selector.JP {
  background-position: 5px -3499px;
}

.country-selector.IL {
  background-position: 5px -3709px;
}

.country-selector.IE {
  background-position: 5px -3744px;
}

.country-selector.ID {
  background-position: 5px -3779px;
}

.country-selector.GB {
  background-position: 5px -4304px;
}

.country-selector.DE {
  background-position: 5px -4934px;
}

.country-selector.C2 {
  background-position: 5px -5144px;
}

.country-selector.CA {
  background-position: 5px -5319px;
}

.country-selector.BR {
  background-position: 5px -5494px;
}

.country-selector.AU {
  background-position: 5px -5984px;
}

.country-selector.AT {
  background-position: 5px -6019px;
}

.country-selector.DO {
  background-position: 5px -4794px;
}

.country-selector.IS {
  background-position: 5px -3639px;
}

.country-selector.PA {
  background-position: 5px -1889px;
}

.country-selector.SC {
  background-position: 5px -1364px;
}

.country-selector.IN {
  background-position: 5px -3674px;
}

.country-selector.ME {
  background-position: 5px -6859px;
}

.country-selector.CI {
  background-position: 5px -7139px;
}
/** Overrides globalnav-header style that is overriding the fixed position */
div[id*=globalnav-header] ~ .video-container {
  position: fixed;
}
.video-container {
  position: fixed;
  width: 100%;
  z-index: 10000;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  left: 0;
  top: 0;
  display: none;
}
.video-container.fade {
  animation: fade-in 0.25s ease-out 0s both;
}
.video-container .x-icon-container {
  position: absolute;
  top: 0px;
  right: 0px;
  padding: 20px;
  display: inline-block;
  width: 70px;
  height: 70px;
  box-sizing: border-box;
}
.video-container .x-icon-container .x-icon {
  cursor: pointer;
  color: white;
  font-size: 30px;
  line-height: 30px;
}
.video-container .video-wrapper {
  box-sizing: border-box;
  padding: 70px;
  position: absolute;
  top: 50%;
  transform: translate3d(-50%, -50%, 0);
  -ms-transform: translate(-50%, -50%);
  left: 50%;
  width: 100%;
  height: 100%;
  max-width: 1780px;
  max-height: 940px;
}
.video-container .video-wrapper video {
  background-color: black;
  margin: 0 auto;
  width: 100%;
  height: 100%;
  border: solid 1px rgba(255, 255, 255, 0.2);
}
.ppme-video-mobile {
  width: 0;
  height: 0;
}
.spinner {
  width: 30px;
  height: 30px;
  border: 8px solid rgba(0, 0, 0, 0.2);
  border-top-color: #2180c0;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -22px;
  margin-left: -22px;
  animation: spin 0.7s linear infinite;
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .spinner {
    background: url('https://www.paypalobjects.com/webstatic/checkout/hermes/icon_loader_med.gif');
    border: none;
    animation: none;
    width: 46px;
    height: 46px;
  }
}
.content-separator {
  margin-top: 15px;
  margin-bottom: 15px;
  border-bottom: 1px solid #f2f2f2;
  height: 0;
}
.content-separator.dotted {
  border-bottom: 1px dotted #b7bcbf;
}
.content-separator.fill {
  margin-left: -999px;
  margin-right: -999px;
  margin-top: 25px;
  margin-bottom: 25px;
}
@media screen and (min-width: 768px) {
  .content-separator.fill {
    margin-left: -97px;
    margin-right: -97px;
  }
}
html,
body {
  margin: 0;
  padding: 0;
}
html {
  height: 100%;
}
html[lang='ko_KR'] * {
  word-break: keep-all;
}
body {
  background-color: #fff;
  height: 100%;
  -moz-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
}
@media screen and (min-width: 768px) {
  body {
    background-color: #f7f9fa;
  }
}
body .wrapper {
  box-sizing: border-box;
  position: relative;
  min-height: calc(90% - 50px);
  width: 100%;
  padding-bottom: 130px;
}
@media screen and (min-width: 768px) {
  body .wrapper {
    min-height: calc(99.7% - 70px);
  }
}

body.landing {
  padding: 0;
  margin: 0;
  background-color: #fff;
  /* -------------------------------------------------------------------------------------------------- */
}
body.landing section {
  padding-left: 32px;
  padding-right: 32px;
  position: relative;
}
@media screen and (max-width: 767px) {
  body.landing section {
    padding-left: 20px;
    padding-right: 20px;
  }
}
body.landing section .content {
  max-width: 940px;
  margin: 0 auto;
}
body.landing section.title {
  min-height: 600px;
  box-sizing: border-box;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  overflow: hidden;
}
@media screen and (min-width: 768px) {
  body.landing section.title {
    background-color: #2c2e2f;
    background-position: 50% 20%;
    background-image: url('https://www.paypalobjects.com/digitalassets/c/consumer/paypalme/networkidentity/paypal-me-mobile-heroimage.jpg');
  }
}
@media screen and (min-width: 768px) and (-webkit-min-device-pixel-ratio: 2), screen and (min-width: 768px) and (min-resolution: 192dpi) {
  body.landing section.title {
    background-image: url('https://www.paypalobjects.com/digitalassets/c/consumer/paypalme/networkidentity/paypal-me-mobile-heroimage_2x_mini.jpg');
  }
}
@media screen and (min-width: 768px) and screen and (min-width: 1600px) {
  body.landing section.title {
    background-image: url('https://www.paypalobjects.com/digitalassets/c/consumer/paypalme/networkidentity/paypal-me-mobile-heroimage_2x_mini.jpg');
  }
}
@media screen and (max-width: 767px) {
  body.landing section.title {
    min-height: 0;
    padding-top: 265px;
    background-size: auto 265px;
    background-position: 50% top;
    background-image: url('https://www.paypalobjects.com/digitalassets/c/consumer/paypalme/networkidentity/paypal-me-mobile-heroimage.jpg');
  }
}
@media screen and (max-width: 767px) and (-webkit-min-device-pixel-ratio: 2), screen and (max-width: 767px) and (min-resolution: 192dpi) {
  body.landing section.title {
    background-image: url('https://www.paypalobjects.com/digitalassets/c/consumer/paypalme/networkidentity/paypal-me-mobile-heroimage_2x_mini.jpg');
  }
}
@media screen and (min-width: 768px) {
  body.landing section.title .content {
    margin: 0;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    max-width: 780px;
    width: 100%;
  }
}
body.landing section.title .content h1 {
  color: #fff;
  font-family: 'PayPal-Sans-Big', 'HelveticaNeue-Light', 'PayPal-Sans-Big', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-weight: 300;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 40px;
  line-height: 50px;
}
@media screen and (max-width: 767px) {
  body.landing section.title .content h1 {
    text-align: center;
    color: #0070ba;
    font-family: 'PayPal-Sans-Big', 'HelveticaNeue-Light', 'PayPal-Sans-Big', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-weight: 300;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-size: 32px;
    line-height: 38px;
    margin-top: 30px;
    margin-bottom: 22px;
  }
}
@media screen and (min-width: 768px) {
  body.landing section.title .content h1 {
    margin: 0 auto 30px;
  }
}
body.landing section.title .content h2 {
  color: #fff;
  text-align: center;
  font-family: 'PayPal-Sans-Big', 'HelveticaNeue-Light', 'PayPal-Sans-Big', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-weight: 300;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 28px;
  line-height: 35px;
}
@media screen and (min-width: 768px) {
  body.landing section.title .content h2 {
    max-width: 620px;
    margin: 30px auto;
  }
}
@media screen and (max-width: 767px) {
  body.landing section.title .content h2 {
    color: #2c2e2f;
    font-family: 'PayPal-Sans-Big', 'HelveticaNeue-Light', 'PayPal-Sans-Big', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-weight: 300;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-size: 20px;
    line-height: 25px;
    margin-top: 22px;
    margin-bottom: 24px;
  }
}
body.landing section.title .content .grab-button {
  margin: 0 auto;
  max-width: 300px;
}
@media screen and (min-width: 768px) {
  body.landing section.title .content .grab-button {
    margin-top: 44px;
  }
}
@media screen and (min-width: 768px) {
  body.landing section.title .biz-lp {
    position: absolute;
    bottom: 37px;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, 0);
    color: #fff;
    font-family: 'PayPal-Sans-Big', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-weight: 400;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-size: 15px;
    line-height: 18px;
  }
  body.landing section.title .biz-lp a {
    font-weight: 600;
    color: #fff;
  }
}
@media screen and (max-width: 767px) {
  body.landing section.title .biz-lp {
    color: #0070ba;
    margin-top: 24px;
    font-family: 'PayPal-Sans-Big', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-weight: 400;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-size: 20px;
    line-height: 18px;
    text-align: center;
  }
  body.landing section.title .biz-lp a {
    margin-top: 8px;
    display: block;
    font-weight: bold;
  }
}
body.landing section.title .biz-lp a {
  cursor: pointer;
}
body.landing section.phones {
  padding-top: 70px;
  padding-bottom: 80px;
}
@media screen and (max-width: 600px) {
  body.landing section.phones {
    padding-bottom: 50px;
    overflow: hidden;
  }
}
body.landing section.phones .phone-images {
  text-align: center;
  border-bottom: solid 1px #ccc;
  max-width: 940px;
  margin: 0 auto;
}
@media screen and (max-width: 600px) {
  body.landing section.phones .phone-images .phone-images-wrapper {
    margin: 0 auto;
    position: relative;
    width: 267px;
    height: 298px;
  }
}
body.landing section.phones .phone-images .phone-img {
  display: inline-block;
  width: 28.40425532%;
  max-width: 267px;
  vertical-align: bottom;
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: top;
}
body.landing section.phones .phone-images .phone-img:after {
  padding-top: 111.61048689%;
  display: block;
  content: '';
}
@media screen and (min-width: 601px) {
  body.landing section.phones .phone-images .phone-img {
    margin-right: 2.44680851% /*rtl:ignore*/;
    margin-left: 2.44680851% /*rtl:ignore*/;
  }
}
@media screen and (max-width: 600px) {
  body.landing section.phones .phone-images .phone-img {
    width: 267px;
    position: absolute;
    top: 0;
    left: 0 /*rtl:ignore*/;
  }
}
@keyframes phone-image-animation {
  0% {
    transform: translate3d(50vw, 0, 0) translate3d(133.5px, 0, 0) /*rtl:ignore*/;
    opacity: 0.4;
  }
  5.56% {
    transform: translate3d(0, 0, 0) /*rtl:ignore*/;
    opacity: 1;
  }
  32.22222222222222% {
    transform: translate3d(0, 0, 0) /*rtl:ignore*/;
    opacity: 1;
  }
  36.666666666666664% {
    transform: translate3d(-50vw, 0, 0) translate3d(-133.5px, 0, 0) /*rtl:ignore*/;
    opacity: 0.4;
  }
  100% {
    transform: translate3d(-50vw, 0, 0) translate3d(-133.5px, 0, 0) /*rtl:ignore*/;
    opacity: 0.4;
  }
}
body.landing section.phones .phone-images .phone-img.phone1 {
  background-image: url('https://www.paypalobjects.com/digitalassets/c/consumer/paypalme/networkidentity/Brianna_2X_cropped.jpg');
}
@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  body.landing section.phones .phone-images .phone-img.phone1 {
    background-image: url('https://www.paypalobjects.com/digitalassets/c/consumer/paypalme/networkidentity/Brianna_2X_cropped.jpg');
  }
}
html[lang$='AU'] body.landing section.phones .phone-images .phone-img.phone1 {
  background-image: url('https://www.paypalobjects.com/digitalassets/c/consumer/paypalme/networkidentity/iPhone-X-Mockup_1_cropped_AU.png');
}
@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  html[lang$='AU'] body.landing section.phones .phone-images .phone-img.phone1 {
    background-image: url('https://www.paypalobjects.com/digitalassets/c/consumer/paypalme/networkidentity/iPhone-X-Mockup_1_cropped_AU.png');
  }
}
@media screen and (max-width: 600px) {
  body.landing section.phones .phone-images .phone-img.phone1 {
    animation: phone-image-animation 9000ms ease-out -1ms both infinite;
  }
}
body.landing section.phones .phone-images .phone-img.phone2 {
  background-image: url('https://www.paypalobjects.com/digitalassets/c/consumer/paypalme/networkidentity/David_2X_cropped.jpg');
}
@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  body.landing section.phones .phone-images .phone-img.phone2 {
    background-image: url('https://www.paypalobjects.com/digitalassets/c/consumer/paypalme/networkidentity/David_2X_cropped.jpg');
  }
}
html[lang$='AU'] body.landing section.phones .phone-images .phone-img.phone2 {
  background-image: url('https://www.paypalobjects.com/digitalassets/c/consumer/paypalme/networkidentity/iPhone-X-Mockup_2_cropped_AU.png');
}
@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  html[lang$='AU'] body.landing section.phones .phone-images .phone-img.phone2 {
    background-image: url('https://www.paypalobjects.com/digitalassets/c/consumer/paypalme/networkidentity/iPhone-X-Mockup_2_cropped_AU.png');
  }
}
@media screen and (max-width: 600px) {
  body.landing section.phones .phone-images .phone-img.phone2 {
    animation: phone-image-animation 9000ms ease-out -3000ms both infinite;
  }
}
body.landing section.phones .phone-images .phone-img.phone3 {
  background-image: url('https://www.paypalobjects.com/digitalassets/c/consumer/paypalme/networkidentity/Merchant_2X_cropped.jpg');
}
@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  body.landing section.phones .phone-images .phone-img.phone3 {
    background-image: url('https://www.paypalobjects.com/digitalassets/c/consumer/paypalme/networkidentity/Merchant_2X_cropped.jpg');
  }
}
html[lang$='AU'] body.landing section.phones .phone-images .phone-img.phone3 {
  background-image: url('https://www.paypalobjects.com/digitalassets/c/consumer/paypalme/networkidentity/iPhone-X-Mockup_3_cropped_AU.png');
}
@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  html[lang$='AU'] body.landing section.phones .phone-images .phone-img.phone3 {
    background-image: url('https://www.paypalobjects.com/digitalassets/c/consumer/paypalme/networkidentity/iPhone-X-Mockup_3_cropped_AU.png');
  }
}
@media screen and (max-width: 600px) {
  body.landing section.phones .phone-images .phone-img.phone3 {
    animation: phone-image-animation 9000ms ease-out -6000ms both infinite;
  }
}
body.landing section.phones .phone-under-contents {
  text-align: center;
  padding-top: 29px;
  box-sizing: border-box;
  max-width: 940px;
  margin: 0 auto;
}
body.landing section.phones .phone-under-contents .phone-under-content {
  vertical-align: top;
  display: inline-block;
  text-align: center;
  width: 28.40425532%;
  margin-right: 2.44680851%;
  margin-left: 2.44680851%;
}
@media screen and (max-width: 600px) {
  body.landing section.phones .phone-descriptions {
    display: none;
  }
}
body.landing section.phones .phone-descriptions .phone-description-header {
  font-family: 'PayPal-Sans-Big', 'HelveticaNeue-Light', 'PayPal-Sans-Big', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-weight: 300;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 20px;
  line-height: 20px;
  text-align: left;
  margin-bottom: 15px;
}
body.landing section.phones .phone-descriptions .phone-description-content {
  font-family: 'PayPal-Sans-Big', 'HelveticaNeue-Light', 'PayPal-Sans-Big', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-weight: 300;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 15px;
  line-height: 20px;
  color: #6c7378;
  text-align: left;
  margin-bottom: 20px;
}
@media screen and (min-width: 601px) {
  body.landing section.phones .phone-links {
    display: none;
  }
}
@media screen and (max-width: 600px) {
  body.landing section.phones .phone-links {
    position: relative;
    padding: 17px 0;
    height: 52px;
  }
}
body.landing section.phones .phone-links .phone-link {
  font-family: 'PayPal-Sans-Big', 'HelveticaNeue-Medium', 'PayPal-Sans-Big', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-weight: 500;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 15px;
  line-height: 15px;
}
@media screen and (max-width: 600px) {
  body.landing section.phones .phone-links .phone-link {
    width: 267px;
    font-family: 'PayPal-Sans-Big', 'HelveticaNeue-Medium', 'PayPal-Sans-Big', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-weight: 500;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-size: 18px;
    line-height: 18px;
    height: 30px;
    background-color: white;
    position: absolute;
    top: 17px;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
  }
}
@media screen and (min-width: 601px) {
  body.landing section.phones .phone-links .phone-link {
    margin-right: 2.44680851%;
    margin-left: 2.44680851%;
  }
}
body.landing section.phones .phone-links .phone-link .phone-link-overflow {
  text-align: center;
  margin-left: -100%;
  margin-right: -100%;
}
@keyframes phone-link-animation {
  0% {
    opacity: 0;
  }
  5.555555555555555% {
    opacity: 0;
  }
  7.777777777777778% {
    opacity: 1;
  }
  32.22222222222222% {
    opacity: 1;
  }
  34.44444444444444% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
body.landing section.phones .phone-links .phone-link.phone-link1 {
  color: #a36eb3;
}
@media screen and (max-width: 600px) {
  body.landing section.phones .phone-links .phone-link.phone-link1 {
    animation: phone-link-animation 9000ms ease-out -1ms both infinite;
  }
}
body.landing section.phones .phone-links .phone-link.phone-link2 {
  color: #0070ba;
}
@media screen and (max-width: 600px) {
  body.landing section.phones .phone-links .phone-link.phone-link2 {
    animation: phone-link-animation 9000ms ease-out -3000ms both infinite;
  }
}
body.landing section.phones .phone-links .phone-link.phone-link3 {
  color: #75839a;
}
@media screen and (max-width: 600px) {
  body.landing section.phones .phone-links .phone-link.phone-link3 {
    animation: phone-link-animation 9000ms ease-out -6000ms both infinite;
  }
}
body.landing .video-button {
  margin: 10px auto 0;
  max-width: 300px;
  margin-bottom: 55px;
}
body.landing .video-button .icon-play {
  width: 0;
  height: 0;
  border-top: 6px solid transparent;
  border-bottom: 6px solid transparent;
  border-left: 10px solid #009cde;
  margin-right: 12px;
  overflow: hidden;
  display: inline-block;
}
body.landing h1.how-header {
  font-family: 'PayPal-Sans-Big', 'HelveticaNeue-Light', 'PayPal-Sans-Big', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-weight: 300;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 32px;
  line-height: 45px;
  margin-top: 0;
  margin-bottom: 70px;
  text-align: center;
  color: #2c2e2f;
}
@media screen and (max-width: 767px) {
  body.landing h1.how-header {
    margin-bottom: 50px;
  }
}
body.landing section.how1 {
  background-position: bottom 0px right 40px /*rtl:ignore*/;
  background-repeat: no-repeat;
  height: 413px;
  background-size: auto 413px;
  background-color: #fff;
  margin-bottom: 70px;
  background-image: url('https://www.paypalobjects.com/digitalassets/c/consumer/paypalme/networkidentity/paypal-me-desktop-devices-mini.jpg');
}
@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  body.landing section.how1 {
    background-image: url('https://www.paypalobjects.com/digitalassets/c/consumer/paypalme/networkidentity/paypal-me-desktop-devices-mini.jpg');
  }
}
html[lang$='AU'] body.landing section.how1 {
  background-image: url('https://www.paypalobjects.com/digitalassets/c/consumer/paypalme/networkidentity/paypal-me-desktop-devices_AU.png');
}
@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  html[lang$='AU'] body.landing section.how1 {
    background-image: url('https://www.paypalobjects.com/digitalassets/c/consumer/paypalme/networkidentity/paypal-me-desktop-devices_AU.png');
  }
}
@media screen and (max-width: 1505px) {
  body.landing section.how1 {
    background-position: bottom 0px right -70px /*rtl:ignore*/;
  }
}
@media screen and (max-width: 1290px) {
  body.landing section.how1 {
    background-position: bottom 0px right -160px /*rtl:ignore*/;
  }
}
@media screen and (max-width: 1104px) {
  body.landing section.how1 {
    background-position: bottom 0px right -280px;
  }
}
@media screen and (max-width: 940px) {
  body.landing section.how1 {
    background-position: bottom 0px right -380px /*rtl:ignore*/;
  }
}
@media screen and (max-width: 856px) {
  body.landing section.how1 {
    background-position: bottom 0px right -300px /*rtl:ignore*/;
    background-size: auto 326px;
    height: 326px;
  }
}
@media screen and (max-width: 767px) {
  body.landing section.how1 {
    padding-bottom: 340px;
    height: auto;
    background-position: left 20px top 100% /*rtl:ignore*/;
    background-size: auto 310px;
  }
}
@media screen and (min-width: 640px) and (max-width: 767px) {
  body.landing section.how1 {
    background-position: center bottom 0px /*rtl:ignore*/;
  }
}
@media screen and (max-width: 375px) {
  body.landing section.how1 {
    background-image: url('https://www.paypalobjects.com/digitalassets/c/consumer/paypalme/networkidentity/paypal-me-desktop-devices-mini.jpg');
  }
}
@media screen and (max-width: 375px) and (-webkit-min-device-pixel-ratio: 2), screen and (max-width: 375px) and (min-resolution: 192dpi) {
  body.landing section.how1 {
    background-image: url('https://www.paypalobjects.com/digitalassets/c/consumer/paypalme/networkidentity/paypal-me-desktop-devices-mini.jpg');
  }
}
@media screen and (min-width: 768px) {
  body.landing section.how1 .content {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
  }
}
@media screen and (max-width: 767px) {
  body.landing section.how1 .content .choose {
    margin: 0 auto;
    display: block;
  }
}
body.landing section.how2 {
  height: 530px;
  margin-bottom: 70px;
  background-color: #2c2e2f;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 29% 30% /*rtl:ignore*/;
  position: relative;
  background-image: url('https://www.paypalobjects.com/webstatic/paypalme/images/landing/desktop/paypal-me-desktop-couple.jpg');
}
@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  body.landing section.how2 {
    background-image: url('https://www.paypalobjects.com/webstatic/paypalme/images/landing/desktop/paypal-me-desktop-couple_2x.jpg');
  }
}
@media screen and (min-width: 1600px) {
  body.landing section.how2 {
    background-image: url('https://www.paypalobjects.com/webstatic/paypalme/images/landing/desktop/paypal-me-desktop-couple_2x.jpg');
  }
}
@media screen and (max-width: 767px) {
  body.landing section.how2 {
    background-color: transparent;
    height: auto;
    padding-bottom: 318px;
    background-repeat: no-repeat;
    background-position: 18.9% bottom /*rtl:ignore*/;
    background-size: auto 288px;
    background-image: url('https://www.paypalobjects.com/webstatic/paypalme/images/landing/desktop/paypal-me-desktop-couple.jpg');
  }
}
@media screen and (max-width: 767px) and (-webkit-min-device-pixel-ratio: 2), screen and (max-width: 767px) and (min-resolution: 192dpi) {
  body.landing section.how2 {
    background-image: url('https://www.paypalobjects.com/webstatic/paypalme/images/landing/desktop/paypal-me-desktop-couple_2x.jpg');
  }
}
@media screen and (max-width: 375px) {
  body.landing section.how2 {
    background-color: transparent;
    background-image: url('https://www.paypalobjects.com/webstatic/paypalme/images/landing/desktop/paypal-me-desktop-couple.jpg');
  }
}
@media screen and (max-width: 375px) and (-webkit-min-device-pixel-ratio: 2), screen and (max-width: 375px) and (min-resolution: 192dpi) {
  body.landing section.how2 {
    background-image: url('https://www.paypalobjects.com/webstatic/paypalme/images/landing/desktop/paypal-me-desktop-couple_2x.jpg');
  }
}
body.landing section.how2 .simply {
  position: absolute;
  top: 214px;
  left: 56.75% /*rtl:ignore*/;
}
@media screen and (max-width: 1200px) {
  body.landing section.how2 .simply {
    top: 334px;
  }
}
@media screen and (max-width: 1060px) {
  body.landing section.how2 .simply {
    max-width: 350px;
    right: 70px /*rtl:ignore*/;
    left: auto /*rtl:ignore*/;
  }
}
@media screen and (max-width: 767px) {
  body.landing section.how2 .simply {
    position: relative;
    top: auto;
    left: auto /*rtl:ignore*/;
    right: auto /*rtl:ignore*/;
    margin: 0 auto;
    display: block;
    max-width: none;
  }
}
@media screen and (min-width: 768px) {
  body.landing section.how2 .simply .circled-number {
    color: #fff;
    border-color: #fff;
  }
}
@media screen and (min-width: 768px) {
  body.landing section.how2 .simply .numbered-header {
    color: #fff;
  }
}
body.landing section.how2 .simply p {
  max-width: 100%;
}
@media screen and (max-width: 767px) {
  body.landing section.how2 .simply p {
    max-width: none;
  }
}
@media screen and (min-width: 768px) {
  body.landing section.how2 .simply p {
    color: #fff;
  }
}
body.landing section.how3 {
  background-color: #fff;
  height: 466px;
  background-position: bottom 0px right 20% /*rtl:ignore*/;
  background-repeat: no-repeat;
  background-size: auto 466px;
  background-image: url('https://www.paypalobjects.com/digitalassets/c/consumer/paypalme/networkidentity/Abby_2X_cropped.jpg');
}
@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  body.landing section.how3 {
    background-image: url('https://www.paypalobjects.com/digitalassets/c/consumer/paypalme/networkidentity/Abby_2X_cropped.jpg');
  }
}
html[lang$='AU'] body.landing section.how3 {
  background-image: url('https://www.paypalobjects.com/digitalassets/c/consumer/paypalme/networkidentity/iPhone-X-Mockup_1_AU.png');
}
@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  html[lang$='AU'] body.landing section.how3 {
    background-image: url('https://www.paypalobjects.com/digitalassets/c/consumer/paypalme/networkidentity/iPhone-X-Mockup_1.png_AU');
  }
}
@media screen and (max-width: 1025px) {
  body.landing section.how3 {
    background-position: bottom 0px right 60px /*rtl:ignore*/;
  }
}
@media screen and (max-width: 856px) {
  body.landing section.how3 {
    background-size: auto 367.83535109px;
    height: 367.83535109px;
  }
}
@media screen and (max-width: 767px) {
  body.landing section.how3 {
    height: auto;
    padding-bottom: 386px;
    background-position: left 50% top 100% /*rtl:ignore*/;
    background-size: auto 356px;
    background-image: url('https://www.paypalobjects.com/digitalassets/c/consumer/paypalme/networkidentity/Abby_2X_cropped.jpg');
  }
}
@media screen and (max-width: 767px) and (-webkit-min-device-pixel-ratio: 2), screen and (max-width: 767px) and (min-resolution: 192dpi) {
  body.landing section.how3 {
    background-image: url('https://www.paypalobjects.com/digitalassets/c/consumer/paypalme/networkidentity/Abby_2X_cropped.jpg');
  }
}
@media screen and (min-width: 768px) {
  body.landing section.how3 .content {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
  }
}
@media screen and (max-width: 767px) {
  body.landing section.how3 .content .get-paid {
    margin: 0 auto;
    display: block;
  }
}
body.landing section.reasons {
  background-image: radial-gradient(circle farthest-side at center bottom, #009cde, #003087 125%);
  color: #fff;
  box-sizing: border-box;
  text-align: center;
  padding-top: 100px;
  padding-bottom: 100px;
}
@media screen and (max-width: 767px) {
  body.landing section.reasons {
    padding-top: 70px;
    padding-bottom: 70px;
  }
}
@media screen and (max-width: 767px) {
  body.landing section.reasons {
    padding-top: 56px;
    padding-left: 5.33333333%;
    padding-right: 5.33333333%;
  }
}
body.landing section.reasons h1 {
  margin: 0 auto;
  max-width: 570px;
  text-align: center;
  font-family: 'PayPal-Sans-Big', 'HelveticaNeue-Light', 'PayPal-Sans-Big', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-weight: 300;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 40px;
  line-height: 48px;
  margin-bottom: 44px;
}
body.landing section.reasons .reason {
  box-sizing: border-box;
  display: inline-block;
  width: 29.78723404%;
  vertical-align: top;
  text-align: left;
  padding-right: 5.31914894%;
}
body.landing section.reasons .reason:last-child {
  padding-right: 0;
}
@media screen and (max-width: 767px) {
  body.landing section.reasons .reason {
    width: 100%;
    margin-bottom: 5px;
    padding-right: 0;
  }
}
body.landing section.reasons .reason h2 {
  font-family: 'PayPal-Sans-Big', 'HelveticaNeue-Light', 'PayPal-Sans-Big', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-weight: 300;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 22px;
  line-height: 27px;
  margin: 0;
}
body.landing section.reasons .reason p {
  margin-top: 3px;
  font-family: 'PayPal-Sans-Big', 'HelveticaNeue-Light', 'PayPal-Sans-Big', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-weight: 300;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 15px;
  line-height: 22px;
}
body.landing section.get {
  min-height: 400px;
  background-color: #f5f5f5;
  text-align: center;
  padding-top: 70px;
  padding-bottom: 100px;
  box-sizing: border-box;
}
@media screen and (max-width: 767px) {
  body.landing section.get {
    padding-left: 5.33333333%;
    padding-right: 5.33333333%;
  }
}
body.landing section.get h1 {
  font-family: 'PayPal-Sans-Big', 'HelveticaNeue-Light', 'PayPal-Sans-Big', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-weight: 300;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 40px;
  line-height: 40px;
  color: #2c2e2f;
  margin: 0;
}
@media screen and (max-width: 767px) {
  body.landing section.get h1 {
    font-family: 'PayPal-Sans-Big', 'HelveticaNeue-Light', 'PayPal-Sans-Big', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-weight: 300;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-size: 28px;
    line-height: 35px;
  }
}
body.landing section.get p {
  margin: 20px auto 0;
  max-width: 600px;
  font-family: 'PayPal-Sans-Big', 'HelveticaNeue-Light', 'PayPal-Sans-Big', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-weight: 300;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 15px;
  line-height: 22.5px;
  color: #767676;
}
body.landing section.get p a {
  font-family: 'PayPal-Sans-Big', 'HelveticaNeue-Medium', 'PayPal-Sans-Big', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-weight: 500;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 15px;
  line-height: 15px;
}
body.landing section.get .grab-button {
  width: 100%;
  max-width: 300px;
  margin: 19px auto 5px;
}
body.landing .footers-container {
  position: static;
  margin-top: 0;
}
body.landing .numbered-paragraph {
  display: inline-block;
  width: 100%;
  max-width: 410px;
  position: relative;
}
@media screen and (max-width: 767px) {
  body.landing .numbered-paragraph {
    max-width: none;
  }
}
body.landing .numbered-paragraph .circled-number {
  position: absolute;
  top: 0;
  left: 0;
  font-family: 'PayPal-Sans-Big', 'HelveticaNeue-Light', 'PayPal-Sans-Big', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-weight: 300;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 18px;
  line-height: 1.9em;
  width: 36px;
  height: 36px;
  border-radius: 100%;
  border: solid 1px #777;
  color: #2c2e2f;
  display: inline-block;
  text-align: center;
  vertical-align: top;
  box-sizing: border-box;
}
body.landing .numbered-paragraph .numbered-header {
  margin-left: 55px;
  display: inline-block;
  color: #2c2e2f;
  font-family: 'PayPal-Sans-Big', 'HelveticaNeue-Light', 'PayPal-Sans-Big', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-weight: 300;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 28px;
  line-height: 36px;
  vertical-align: middle;
}
@media screen and (max-width: 340px) {
  body.landing .numbered-paragraph .numbered-header {
    max-width: 84%;
  }
}
body.landing .numbered-paragraph p {
  font-family: 'PayPal-Sans-Big', 'HelveticaNeue-Light', 'PayPal-Sans-Big', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-weight: 300;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 15px;
  line-height: 22px;
  margin: 0;
  margin-left: 59px;
  margin-top: 6px;
  width: 100%;
  max-width: 350px;
  width: 84%;
  letter-spacing: 0.2px;
  color: #777;
}
@media screen and (max-width: 767px) {
  body.landing .numbered-paragraph p {
    max-width: none;
    margin-left: 57px;
  }
}
@media screen and (max-width: 340px) {
  body.landing .numbered-paragraph p {
    max-width: 84%;
  }
}


/*# sourceMappingURL=marketing.css.map*/